export const environment = {
  production: false,
  stg: false,
  dev: true,
  wso2: false,
  host: '/',
  frontendURL: 'https://stgdev.parivesh.nic.in/',
  baseURLParivesh1: 'https://stgdev.parivesh.nic.in/',
  pariveshFrontendURL: 'https://stgdev.parivesh.nic.in/',
  baseURL: 'https://stgdev.parivesh.nic.in/parivesh2_dev/',
  baseComplianceURL: 'https://stgdev.parivesh.nic.in/compliance/',
  baseURLCin: 'https://stgdev.parivesh.nic.in/cin/',
  adminUrl: 'https://stgdev.parivesh.nic.in/parivesh_admin/',
  //baseURL: 'https://parivesh.nic.in/parivesh_api/',
  wlcBaseURL:'https://stgdev.parivesh.nic.in/ua-dev/',
  baseURLAgendaMom: 'https://stgdev.parivesh.nic.in/agendamom/',
  baseURLAgendaMomFc: 'https://stgdev.parivesh.nic.in/fcagendamom/',
 // baseURLAgendaMom: 'https://parivesh.nic.in/agendamom/',
  baseURLPdf: 'https://stgdev.parivesh.nic.in/pdfutil/',
  baseURLEngine: 'https://stgdev.parivesh.nic.in/engine/',
  baseURLEngineRest: 'https://stgdev.parivesh.nic.in/engine-rest/',
  baseURLProcessEngine: 'https://stgdev.parivesh.nic.in/workgroupmaster/',
  baseUrlPan: 'https://parivesh.nic.in/validate',
  baseUrlPanNew: 'https://parivesh.nic.in/pan/validate',
  baseURLKMLUpload: 'https://stgdev.parivesh.nic.in/okm-dev/',
  baseURLGisKml: 'https://stgdev.parivesh.nic.in/gis-dev/',
  baseURLMis: 'https://stgdev.parivesh.nic.in/ua-dev/parivesh/',
  // baseURLCafGis: 'https://stgdev.parivesh.nic.in/caf-gisf-dev/',
  baseURLWLC: 'https://stgdev.parivesh.nic.in/ua-dev/',
  baseURLCampaGateway: 'https://stgdev.parivesh.nic.in/campa/',
  baseURLCafGis: 'https://stgdev.parivesh.nic.in/pariveshgis/#/apps/caf',
  baseURLLogin: 'https://stgdev.parivesh.nic.in/parivesh-ua-dev/',
  baseURLAuthorityLogin: 'https://parivesh.nic.in/Login.aspx',
  baseURLKya: 'https://stgdev.parivesh.nic.in/kya-dev/',
  baseURLCamunda: 'https://stgdev.parivesh.nic.in/bpmn-dev/',
  baseURLDSS: 'https://stgdev.parivesh.nic.in/pariveshgis/#/apps/dss',
  baseURLAadhar: 'https://stgdev.parivesh.nic.in/adhaar/',
  wso2URL: 'https://stgapi.parivesh.nic.in/token',
  baseUrlPayment : 'http://164.100.213.215/paymentapi/api/',
  baseURLesign: 'https://stgdev.parivesh.nic.in/esign/',
  baseURLCertificateDownload: 'https://stgdev.parivesh.nic.in/certificates/',
  baseURLPaymentGateway: 'https://stgdev.parivesh.nic.in/bkepay/',
  baseURLDigitalSignature : 'https://eoffsigner.eoffice.gov.in:55103/',
  baseURLDigitalSigner : 'https://stgdev.parivesh.nic.in/digitalsigner/',
  misBaseURL: 'https://stgdev.parivesh.nic.in',
  misDashboard: 'https://stgapm.parivesh.nic.in',
  baseUrlLegacy:'https://parivesh.nic.in/legacy/',
  esignBaseUrl: 'https://stgdev.parivesh.nic.in/',
  baseUrlLegal:'https://stgdev.parivesh.nic.in/legal_repo/',
  baseUrlNgt:'https://stgdev.parivesh.nic.in/ngt/',
  wso2Token:
    'TV9rU2NzMHhxdXR0Y0ZySFM1ME9HUU01U3FvYTpkTWZYbmV3U0x2VVR6YVdkd0RDeWhLVkpQN2Nh',
  //acaform
  baseURLFcGis: 'https://stgdev.parivesh.nic.in/pariveshgis/#/apps/asaformupload',
  baseUrlAi: 'http://164.100.252.47/',
  baseWLCApi: 'https://stgdev.parivesh.nic.in/parivesh-ua-dev/#/'
};

