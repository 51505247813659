import {SessionStorageService} from '../services/session-storage.service';
import {Injectable} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {msg} from '../shared/message-constant';
import {MessageService} from '../services/message.service';
import {
  CAF_STATUS,
  DATE_CALENDER,
  DATE_FORMATS,
  ROLES_KEY,
  SELECTED_ROLE_KEY,
} from '../shared/app-constant';
import {PreviewData} from '../shared/Interface';
import moment from 'moment';
import {StepsModal} from '../models/steps-modal';
import {LocalStorageService} from '../services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ValidationUtility {
  roleDetails: string[] = [];

  constructor(
    private msgService: MessageService,
    private sessionStorageService: SessionStorageService,
    private localStorageService: LocalStorageService
  ) {
  }

  replaceUnderscoreWithHyphen(str: string) {
    return str?.split('_')?.join(' ');
  }

  /**
   * @param form - form within the component.
   * @param controlsToBeUpdated - optional list of controls to be enabled
   * @param isReverseProcess - if true form controls will be disabled
   */
  makeFormControlsEnable(
    form: FormGroup,
    controlsToBeUpdated: string[],
    isReverseProcess?: boolean
  ) {
    controlsToBeUpdated.forEach((item) => {
      isReverseProcess
        ? form.controls[item]?.disable()
        : form.controls[item]?.enable();
      form.controls[item]?.updateValueAndValidity();
    });
  }

  /**
   * Method for changing the validation of the form control based on dropdown selection
   * @param value - boolean expression that is derived from dropdown value.
   * @param form - form within the component.
   * @param controlsActiveOnTrue - will have required validator only if expression of dropdown field returns true. (in case of YES)
   * @param controlsActiveOnFalse - will have required validator only if expression of dropdown field returns false. (in case of NO)
   */
  changeValidation(
    value: boolean,
    form: FormGroup,
    controlsActiveOnTrue: string[],
    controlsActiveOnFalse?: string[]
  ) {
    controlsActiveOnTrue.forEach((formControlName) =>
      this.validation(value, form, formControlName)
    );
    if (controlsActiveOnFalse)
      controlsActiveOnFalse.forEach((formControlName) =>
        this.validation(!value, form, formControlName)
      );
  }

  /**
   * Helper method for changeValidation().
   * @param value - boolean expression.
   * @param form - form within the component.
   * @param formControlName - form control.
   */
  validation(value: boolean, form: FormGroup, formControlName: string) {
    if(form.controls[formControlName]){
          value
            ? form.controls[formControlName].setValidators(
              this.requiredValidator()
                ? this.requiredValidator()
                : Validators.required
            )
            : this.removeControlValidation(form, formControlName);
          form.controls[formControlName].updateValueAndValidity();
      }
  }

  /**
   * Helper method for validation().
   * @param form - form within the component.
   * @param formControlName - form control.
   */
  removeControlValidation(form: FormGroup, formControlName: string) {
    form.controls[formControlName].setValue(null);
    form.controls[formControlName].clearValidators();
  }

  /**
   * Method for changing the validation for add row form controls
   * @param isReset - boolean expression.
   * @param form - form within the component.
   * @param formControlList - form control list that will be updated after adding row for relational data.
   */
  addRemoveValidationAddRow(
    isReset: boolean,
    form: FormGroup,
    formControlList: string[]
  ) {
    formControlList.forEach((formControlName) => {
      if (isReset) {
        form.controls[formControlName].clearValidators();
        form.controls[formControlName].reset();
        form.controls[formControlName].updateValueAndValidity();
      } else {
        this.changeValidation(true, form, [formControlName]);
      }
    });
  }

  /**
   * Used to reset the form array
   * @param form - form within the component.
   * @param formControlList - form control list that will be updated after add row validation fails for controls.
   */
  resetFormArray(form: FormGroup, formControlList: string[]) {
    return new Promise<boolean>((resolve) => {
      let result!: boolean;
      formControlList.forEach((formControlName) => {
        if (
          form.controls[formControlName] &&
          (form.controls[formControlName].invalid ||
          form.controls[formControlName].value == null)
        ) {
          form.controls[formControlName].markAsTouched();
          this.msgService.showError(msg.MANDATORY_FIELD_MSG);
          resolve(true);
        }
      });
      resolve(result);
    });
  }

  /**
   * Used to display message for mandatory fields for add row functionality.
   * @param form - form within the component.
   * @param formControlList - form control list that will be updated after add row validation fails for controls.
   */
  markAsTouched(form: FormGroup, formControlList: string[]) {
    formControlList.forEach((formControlName) =>
      form.controls[formControlName].markAsTouched()
    );
  }

  /**
   * Used to hide message for relational data fields.
   * @param form - form within the component.
   * @param formControlList - form control list that will be updated after add row validation fails for controls.
   */
  markUntouched(form: FormGroup, formControlList: string[]) {
    formControlList.forEach((formControlName) => {
      form.controls[formControlName].markAsUntouched({onlySelf: true});
      this.addRemoveValidationAddRow(true, form, [formControlName]);
    });
  }

  /**
   * Method for changing the validation for add row form controls
   * @param value - value of the form control will be greater than this number.
   * @param form - form within the component.
   * @param controlsForMinValidation - form control list that will be updated for minimum value validation.
   * @param isMinValueAllowed - if 0 is a valid input
   * @param isCustomMessage
   */
  addMinValueValidator(
    value: number,
    form: FormGroup,
    controlsForMinValidation: string[],
    isMinValueAllowed?: boolean,
    isCustomMessage?: boolean
  ) {
    controlsForMinValidation.forEach((item) => {
      // @ts-ignore
      form.controls[item].setValidators(this.requiredAndMinValidator(value, isMinValueAllowed, isCustomMessage));
    });
  }

  minValueValidator(
    value: number,
    form: FormGroup,
    controlsForMinValidation: string[]
  ) {
    controlsForMinValidation.forEach((item) => {
      // @ts-ignore
      form.controls[item].setValidators(this.minValidator(value));
    });
  }

  public minValidator(value: NumberConstructor) {
    return (control: FormControl) => {
      const val = control.value;
      if (val <= value) {
        return {
          min: 'generic.minZero',
        };
      }
      return null;
    };
  }

  /**
   * Method for changing the validation for add row form controls
   * @param value - value of the form control will be less than this number.
   * @param form - form within the component.
   * @param controlsForMaxValidation - form control list that will be updated for minimum value validation.
   * @param isMaxValueAllowed - if 0 is a valid input
   * @param isMaxWithRequired
   */
  addMaxValueValidator(
    value: number,
    form: FormGroup,
    controlsForMaxValidation: string[],
    isMaxValueAllowed?: boolean,
    isMaxWithRequired?: boolean
  ) {
    controlsForMaxValidation.forEach((item) => {
      // @ts-ignore
      form.controls[item].setValidators(
        this.requiredAndMaxValidator(
          value,
          isMaxValueAllowed,
          isMaxWithRequired
        )
      );
    });
  }

  /**
   * Used to update the form values after values are patched and this also removes the error of required field when
   * "No" is patched to the dropdown fields.
   * @param form - form within the component.
   */
  updateValueValidity(form: FormGroup) {
    Object.keys(form.controls).forEach((key) => {
      if (
        key &&
        (form.controls[key].value === true ||
          form.controls[key].value === false)
      )
        form.controls[key].setValue(form.controls[key].value.toString());
      form.controls[key].markAsDirty();
      form.controls[key].updateValueAndValidity();
    });
  }

  /**
   * Checks the validation of an form control in template
   * @param form - form within the component.
   * @param formControlName - form control.
   */
  isInputValid(form: FormGroup, formControlName: string): boolean {
    return (
      (form.controls[formControlName].touched &&
        form.controls[formControlName].pristine) ||
      (form.controls[formControlName].touched &&
        form.controls[formControlName].invalid)
    );
  }

  /**
   *
   * @param module
   * @param steps
   * @param step
   * @param form
   * @param isSetter
   */
  saveRemarks(
    module: string,
    steps: StepsModal[],
    step: number,
    form: FormGroup,
    isSetter?: boolean
  ): PreviewData[] | undefined {
    if (!this.localStorageService.get('isPreviewMode')) return;
    if (isSetter) {
      this.localStorageService.remove(
        module + '_step_' + steps[step - 1]?.name
      );
      let data: PreviewData[] = [];

      Object.keys(form.controls).forEach((item) => {
        let checkbox: any = document.getElementById('checkbox_' + item);
        let remarks: any = document.getElementById('remarks_' + item);
        let component: any = document.getElementById('label_' + item);
        let label: any = document.getElementsByClassName(item);

        if (checkbox && checkbox?.checked) {
          data.push({
            label: component?.textContent || label[0]?.innerText,
            fieldName: item,
            value: form?.controls[item]?.value,
            updatedValue: '',
            remarks: remarks?.value,
            isChecked: checkbox?.checked,
            module,
            step,
          });
        }
      });

      data.sort((a: PreviewData, b: PreviewData) => {
        if (a.label && b.label) {
          if (a.label < b.label) return -1;
          if (a.label > a.label) return 1;
          return 0;
        }
        return 0;
      });

      this.localStorageService.set(
        module + '_step_' + steps[step - 1]?.name,
        JSON.stringify(data)
      );
      return data;
    } else {
      return this.getPreviewRemarks(module, steps, step - 1);
    }
  }

  getPreviewRemarks(module: string, steps: StepsModal[], index: number) {
    let json = this.localStorageService.get(
      module + '_step_' + steps[index]?.name
    );
    if (json) return JSON.parse(json);
  }

  /**
   *
   * @param module
   * @param steps
   * @param step
   * @param form
   */
  patchRemarks(
    module: string,
    steps: StepsModal[],
    step: number,
    form: FormGroup
  ) {
    if (!this.localStorageService.get('isPreviewMode')) return;

    let item = this.saveRemarks(module, steps, step, form, false);

    if (item?.length) {
      item.forEach((data) => {
        let checkbox: any = document.getElementById(
          'checkbox_' + data.fieldName
        );

        if (checkbox) checkbox.checked = data.isChecked;
        let remarks: any = document.getElementById('remarks_' + data.fieldName);

        if (remarks) {
          remarks.classList.remove(data.remarks ? 'd-none' : '');
          remarks.innerHTML = data.remarks;
        }
      });
    }
  }

  isRole(role: string) {
    return new Promise<boolean>((resolve) => {
      let savedRoles = this.sessionStorageService.get(ROLES_KEY);
      if (savedRoles) this.roleDetails = JSON.parse(savedRoles);
      if (this.roleDetails.includes(role)) resolve(true);
      resolve(false);
    });
  }

  isSelectedRole(role: string) {
    return new Promise<boolean>((resolve) => {
      let selectedRole = this.sessionStorageService.get(SELECTED_ROLE_KEY);
      if (selectedRole?.toLowerCase() == role?.toLowerCase()) resolve(true);
      resolve(false);
    });
  }

  getDdMmYyDateFormat(date: string) {
    return moment(date).format(DATE_FORMATS.DISPLAY_FORMAT);
  }

  /**
   *
   * @param dateOne
   * @param dateTwo
   * @param type : type of comparison
   */
  getDateComparison(dateOne: any, dateTwo: any, type: string) {
    switch (type) {
      case DATE_CALENDER.DAYS:
        return moment(dateOne).diff(moment(dateTwo), 'days');
    }
    return 0;
  }

  isFormReadonly(status: string, data?: any) {
    return status === CAF_STATUS.SUBMITTED;
  }

  getFixedLength(
    form: FormGroup,
    formControlName: string,
    length: number
  ): number {
    if (form.controls[formControlName].value) {
      let value: number = +form.controls[formControlName].value;
      return +value.toFixed(length);
    }
    return 0;
  }

  getFixedLengthValue(value: number, length: number): number {
    if (value) return +value.toFixed(length);
    else return 0;
  }

  /** Validate the text passed */
  validateText(str: string, length?: number, maxLength?: number): boolean {
    str = str ? str.toString() : '';
    if (str) {
      return !(
        !str.trim() ||
        str.trim() === '' ||
        (length && str.length < length) ||
        (maxLength && str.length > maxLength)
      );
    }
    return false;
  }

  public digitsAndSpecialCharacter = function () {
    return (control: FormControl) => {
      const name = control.value;
      const regex =
        /^(\d|\-|\_|\.|\`|\~|\!|\@|\#|\$|\%|\^|\&|\*|\(|\)|\\|\||\{|\}|\[|\]|\;|\:|\'|\"|\,|\/|\<|\>|\?|\+|\=)+$/;
      if (name && !regex.test(name)) {
        return {
          digits_special_character: 'generic.digits_special_character',
        };
      }
      return null;
    };
  };

  // Required validator function
  public requiredValidator(fieldName: string = '') {
    return (control: FormControl | AbstractControl) => {
      const val = control.value;

      if ((!val || !this.validateText(val)) && val != 0) {
        return {
          required: 'generic.required',
        };
      } else if (val === '') {
        return {
          required: 'generic.required',
        };
      }
      return null;
    };
  }

  // Required And Min validator function
  public requiredAndMinValidator(
    value: number,
    isMinValueAllowed?: boolean,
    isCustomMessage?: boolean
  ) {
    return (control: FormControl) => {
      const val = control.value;
      if (!isMinValueAllowed && !isCustomMessage) {
        if ((!val || !this.validateText(val)) && val != 0) {
          return {
            required: 'generic.required',
          };
        } else if (val < value || val == '.') {
          return {
            min: 'generic.minZero',
          };
        }
      } else {
        if (val < value && isMinValueAllowed && !isCustomMessage) {
          return {
            minWithZero: 'generic.minWithZero',
          };
        } else if (val < value && isCustomMessage || val == '.') {
          return {
            minWithEqualTo: 'generic.minWithEqualTo',
          };
        }
      }
      return null;
    };
  }

  public equalToValidator(
    value: number,
    form: FormGroup,
    formControlName: string,
  ) {
    return (control: FormControl | AbstractControl) => {
      const val = form.controls[formControlName].value;
        if (val != value) {
          return {
            equalTo: 'generic.equalTo',
          };
        }
      return null;
    };
  }

  // Required And Min validator function
  public requiredAndMaxValidator(
    value: number,
    isMaxValueAllowed?: boolean,
    isMaxWithRequired?: boolean
  ) {
    return (control: FormControl | AbstractControl) => {
      const val = control.value;
      if (!isMaxValueAllowed) {
        if ((!val || !this.validateText(val)) && val != 0) {
          return {
            required: 'generic.required',
          };
        } else if (val > value) {
          return {
            max: 'generic.maxValue',
          };
        }
      } else if (isMaxValueAllowed) {
        if ((!val || !this.validateText(val)) && val != 0) {
          return {
            required: 'generic.required',
          };
        } else if (val > value) {
          return {
            maxWithEqualTo: 'generic.maxWithEqualTo',
          };
        }
      }
      return null;
    };
  }

  // Required validator function
  public maxlengthValidator(fieldName: string = '', length: number) {
    return (control: FormControl | AbstractControl) => {
      const name = control.value;
      if (name && !this.validateText(name, length)) {
        return {
          maxlength: `${fieldName} can't be greater than ${length} characters`,
        };
      }
      return null;
    };
  }

  // Required validator function
  public minlengthValidator(length: number) {
    return (control: FormControl | AbstractControl) => {
      const name = control.value;
      if (name && !this.validateText(name, length)) {
        return {
          minlength: `Value can't be less than ${length} characters`,
        };
      }
      return null;
    };
  }

  // Email form control validator function
  public emailValidator = function () {
    return (control: FormControl) => {
      const name = control.value;
      const regex =
        /^([A-Za-z0-9_\-\.]+)@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,3})$/;
      if (name && !regex.test(name)) {
        return {
          invalid_email: 'generic.invalid_email',
        };
      }
      return null;
    };
  };

  // Govt Email form control validator function
  public govtEmailValidator = function () {
    return (control: FormControl) => {
      const name = control.value;
      const regex = /^([A-Za-z0-9\-\_\.]{2,119}[A-Za-z0-9]@([A-Za-z0-9]+\\.){0,}(gov|nic|hpmail|jcboseust.ac|ac)\\.in)$/;
      if (name && !regex.test(name)) {
        return {
          invalid_email: 'generic.invalid_email',
        };
      }
      return {
        invalid_email: 'generic.invalid_email',
      }
    };
  };

  // Only numeric validator
  public onlyNumber(fieldName: string = '') {
    return (control: FormControl) => {
      const name = control.value;
      const regex = /^[0-9]*$/;
      if (name && !regex.test(name)) {
        return {
          onlyNumber:
            'Please enter a valid ' + fieldName + '. Only numbers are allowed.',
        };
      }
      return null;
    };
  }

  // Only numeric validator
  public individualPanValidator() {
    return (control: FormControl) => {
      const name = control.value;
      const regex = /^[A-Za-z]{3}[Pp][A-Za-z]{1}[0-9]{4}[A-Za-z]{1}$/;
      if (name && !regex.test(name)) {
        return {
          invalid_pan: 'generic.invalid_pan',
        };
      }
      return null;
    };
  }

  // URL Validator
  public urlValidator() {
    return (control: FormControl) => {
      const name = control.value;
      const regex =
        "(https?://)?([\\\\da-z.-]+)\\\\.([a-z.]{2,6})[/\\\\w .-]*/?')";
      // if (name && !regex.test(name)) {
      //   return {
      //     invalid_pan: 'generic.invalid_pan',
      //   };
      // }
      return null;
    };
  }

  // Mobile form control validator function
  public mobileValidator = function () {
    return (control: FormControl) => {
      const name = control.value;
      const regex = /^[6789][0-9]{9}$/;
      if (name && !regex.test(name)) {
        return {
          invalid_mobile: 'generic.invalid_mobile',
        };
      }
      return null;
    };
  };

  // Pin Code form control validator function
  public pinCodeValidator = function () {
    return (control: FormControl) => {
      const name = control.value;
      const regex = /^[1-9]{1}[0-9]{2}[0-9]{3}$/;
      if (name && !regex.test(name)) {
        return {
          invalid_pincode: 'generic.invalid_pincode',
        };
      }
      return null;
    };
  };

  public yyMM = function () {
    return (control: FormControl | any) => {
      const name = control.value;
      const regex = /^\d{2}\/\d{2}$/;
      const regex_match = /^([0-9][0-9]|(3)[0-9])(\/)(((0)[0-9])|((1)[0-2]))/g;
      if (name && !regex.test(name)) {
        return {
          invalid_yymm: 'generic.invalid_yymm',
        };
      } else if (name && !regex_match.test(name)) {
        return {
          invalid_yymm: 'generic.invalid_yymm_format',
        };
      }
      return null;
    };
  };

  public yyyy = function () {
    return (control: FormControl | any) => {
      const value = control.value;
      const regex = /(?<!\d)(?!0000)\d{4}(?!\d)/;
      if (value && !regex.test(value)) {
        if (value == '0000') {
          return {
            invalid_yyyy_invalid_date: 'generic.invalid_yyyy_invalid_date',
          };
        } else {
          return {
            invalid_yyyy: 'generic.invalid_yyyy',
          };
        }
      }
      return null;
    };
  };

  public yyyyYyyy = () => {
    return (control: FormControl | any) => {
      const value = control.value;
      const regex = /^\d{4}\-\d{4}$/;
      if (value && !regex.test(value)) {
        return {
          invalid_yyyyYyyy: 'generic.invalid_yyyyYyyy',
        };
      }
      return null;
    };
  };

  public ratio = () => {
    return (control: FormControl | any) => {
      const name = control.value;
      const regex = /^\d{2}\:\d{2}$/;
      if (name && !regex.test(name)) {
        return {
          invalid_ratio: 'generic.invalid_ratio',
        };
      }
      return null;
    };
  };

  // Yewar Range Validator

  addRangeValidator(
    value: string,
    form: FormGroup,
    controlsForMinValidation: string[],
    isMinValueAllowed?: boolean,
    isCustomMessage?: boolean
  ) {
    controlsForMinValidation.forEach((item) => {
      // @ts-ignore
      form.controls[item].setValidators(this.requiredAndRangeValidator(value, isMinValueAllowed, isCustomMessage));
    });
  }

  requiredAndRangeValidator(
    value: string,
    isMinValueAllowed?: boolean,
    isCustomMessage?: boolean
  ) {
    return (control: FormControl) => {
      const val = control.value;
      const values = value.split('-');
      const regex = /^\d{4}\-\d{4}$/;
      if (val && !regex.test(val)) {
        return {
          invalid_yyyyYyyy: 'generic.invalid_yyyyYyyy',
        };
      }
      if (!isMinValueAllowed && !isCustomMessage) {
        if ((!val || !this.validateText(val)) && val != 0) {
          return {
            required: 'generic.required',
          };
        } else {
          const vals = val.split("-");
          if ((vals[0] < values[0]) || (vals[1] > values[1]) || (vals[0] >= vals[1])) {
            return {
              invalid_year_range: 'generic.invalid_year_range',
            }
          }
        }
      } else {
      }
      return null;
    };
  }

  maskaccountNumber(string:any){
    return string.replace(/.(?=.{4})/g, 'x');
  }

  maskPhoneNumber(phone:any) {
    return phone.replace(/.(?=.{4})/g, 'x'); 
  }

  maskEmail(email:any) {
    const [local, domain] = email.split('@');
    const maskedLocal = local.slice(0, 2) + 'x'.repeat(local.length - 2);
    return maskedLocal + '@' + domain;
  }



}
