import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { AirportProposal } from 'src/app/models/environment-clearance-part-B/airport-proposal.model';
import { AreaSubmerge } from 'src/app/models/environment-clearance-part-B/area-submerged.model';
import { CroppingPattern } from 'src/app/models/environment-clearance-part-B/cropping-patten.model';
import { IrrigationCapacityVillage } from 'src/app/models/environment-clearance-part-B/irrigation-project-capacity.model';
import { MiningProposal } from 'src/app/models/environment-clearance-part-B/mining-proposal.model';
import { RiverValley } from 'src/app/models/environment-clearance-part-B/river-valley.model';
import { EcOtherDetails } from 'src/app/models/environment-clearence/ec-other-details.model';
import { FcAforestationDetail } from 'src/app/models/forest-clearance/fc-aforestation-details.model';
import { FcEnclosureDetails } from 'src/app/models/forest-clearance/fc-enclosure.model';
import { FcOtherDetails } from 'src/app/models/forest-clearance/fc-other.model';
import { FcProjectDetails } from 'src/app/models/forest-clearance/fc-project-details.model';
import { FcProposedLand } from 'src/app/models/forest-clearance/fc-proposed-land.model';
import { ProposalStatus } from 'src/app/models/forest-clearance/proposal-status.model';
import { GenericResponse } from 'src/app/models/shared/generic-response.model';
import { API } from 'src/app/shared/app-constant';
import { environment } from 'src/environments/environment';
import { MessageService } from '../message.service';

@Injectable({
  providedIn: 'root',
})
export class ForestClearancesService {
  constructor(
    private http: HttpClient,
    private msgService: MessageService,
    private translateService: TranslateService
  ) {}

  saveProjectDetails(
    body: FcProjectDetails,
    caf_id: string,
    clearanceId: any
  ): Observable<any> {
    let url = environment.baseURL + API.SAVE_FC_PROJECT_DETAILS;
    let param = new HttpParams()
      .append('caf_id', caf_id)
      .append('clearance_id', clearanceId);
    return this.http.post<any>(url, body, { params: param });
  }

  updateDivisionPatches(body: any, id: number): Observable<any> {
    let url = environment.baseURL + API.UPDATE_FC_DIVISION_PATCH_DETAILS;
    let param = new HttpParams().append('id', id);
    return this.http.post<any>(url, body, { params: param });
  }

  getProjectCategory(): Observable<any> {
    let url = environment.baseURLMis + API.GET_FC_PROJECT_CATEGORY;
    let param = new HttpParams().append('isactive', true);
    return this.http.get<any>(url, { params: param });
  }

  saveProposalInfo(
    body: ProposalStatus[],
    fc_id: string
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_FC_PRIOR_APPROVAL;
    let param = new HttpParams().append('fc_id', fc_id);
    return this.http.post<GenericResponse>(url, body, { params: param });
  }

  saveProposedLandDetails(
    body: FcProposedLand,
    fc_id: string
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_FC_PROPOSED_LAND;
    let param = new HttpParams().append('fc_id', fc_id);
    return this.http.post<GenericResponse>(url, body, { params: param });
  }

  saveAforestationDetails(
    body: FcAforestationDetail,
    fc_id: string
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_FC_AFORESTATION_DETAILS;
    let param = new HttpParams().append('fc_id', fc_id);
    return this.http.post<GenericResponse>(url, body, { params: param });
  }

  saveOtherDetails(
    body: FcOtherDetails,
    fc_id: string
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_FC_OTHER_DETAILS;
    let param = new HttpParams().append('fc_id', fc_id);
    return this.http.post<GenericResponse>(url, body, { params: param });
  }

  saveEnclosuresDetails(
    body: FcEnclosureDetails,
    fc_id: number
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_FC_ENCLOSURE_DETAILS;
    let param = new HttpParams().append('fc_id', fc_id);
    return this.http.post<GenericResponse>(url, body, { params: param });
  }

  saveAirportProposal(body: AirportProposal, id: number) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API.SAVE_FC_AIRPORT_PROPOSAL,
      body,
      {
        params: { fcId: id },
      }
    );
  }

  saveMiningProposal(body: MiningProposal, id: number) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API.SAVE_FC_MINING_PROPOSAL,
      body,
      {
        params: { fcId: id },
      }
    );
  }

  saveRiverValleyProject(body: RiverValley, id: number) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API.SAVE_FC_RIVER_VALLEY,
      body,
      {
        params: { fcId: id },
      }
    );
  }

  saveFcAreaSubMerged(
    body: AreaSubmerge[],
    id: any
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_FC_AREA_SUBMERGED;
    let param = new HttpParams().append('fcId', id);
    return this.http.post<any>(url, body, { params: param });
  }

  deleteFcAreaSubmerged(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.DELETE_FC_AREA_SUBMERGED;
    let param = new HttpParams().append('id', id);
    return this.http.post<any>(url, null, { params: param });
  }

  saveFcIrrigationVillage(
    body: IrrigationCapacityVillage[],
    id: any
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_FC_IRRIGATION_VILLAGE;
    let param = new HttpParams().append('fcId', id);
    return this.http.post<any>(url, body, { params: param });
  }

  deleteFcIrrigationVillage(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.DELETE_FC_IRRIGATION_VILLAGE;
    let param = new HttpParams().append('id', id);
    return this.http.post<any>(url, null, { params: param });
  }

  saveFcCroppingPattern(
    body: CroppingPattern[],
    id: any
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_FC_CROPPING_PATTERN;
    let param = new HttpParams().append('fcId', id);
    return this.http.post<any>(url, body, { params: param });
  }

  deleteFcCroppingPattern(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.DELETE_FC_CROPPING_PATTERN;
    let param = new HttpParams().append('id', id);
    return this.http.post<any>(url, null, { params: param });
  }

  getFcDetails(fc_id: string): Observable<any> {
    let url = environment.baseURL + API.GET_FC_DETAILS;
    let param = new HttpParams().append('fc_id', fc_id);
    return this.http.post<GenericResponse>(url, {}, { params: param });
  }

  getAirportProposal(id: string): Observable<any> {
    let url = environment.baseURL + API.GET_AIRPORT_PROPOSAL_DETAILS;
    let param = new HttpParams().append('cafId', id);
    return this.http.get<GenericResponse>(url, { params: param });
  }

  saveUndertaking(
    body: EcOtherDetails,
    fc_id: any,
    is_submit: boolean
  ): Observable<any> {
    return this.http.post<any>(
      environment.baseURL + API.SAVE_FC_UNDERTAKING,
      body,
      { params: { fc_id, is_submit } }
    );
  }

  getAllDivision(
    stateID: string | number,
    circleID: string | number
  ): Observable<any> {
    let url = environment.baseURLMis + API.GET_ALL_DIVISION;
    let param = new HttpParams()
      .append('state_id', stateID)
      .append('circleId', circleID);
    return this.http.get<GenericResponse>(url, { params: param });
  }

  // getAllRange(division: string = '', stateId: any = '', circle:any = ''): Observable<any> {
  //   let url = environment.baseURLMis + API.GET_ALL_RANGE;
  //   let param = new HttpParams()
  //     .append('division_id', division)
  //     .append('circleId', circle)
  //     .append('state_id', stateId);

  //   return this.http.get<GenericResponse>(url, { params: param });
  // }
  getAllRange(division: string = '', stateId: any = ''): Observable<any> {
    console.log("ID @@",division,stateId);
    
    let url = environment.baseURLMis + API.GET_ALL_RANGE;
    let param = new HttpParams()
      .append('divisionId', division)
      .append('state_id', stateId);

    return this.http.get<GenericResponse>(url, { params: param });
  }

  saveFcPatchKmlDetails(body: any, fc_id: number): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_FC_PATCH_KML_DETAILS;
    let param = new HttpParams().append('forest_clearance_id', fc_id);
    return this.http.post<GenericResponse>(url, body, { params: param });
  }

  saveProposedDiversion(
    body: any[],
    fc_id: number
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_PROPOSED_DIVERSION;
    let param = new HttpParams().append('fc_id', fc_id);
    return this.http.post<GenericResponse>(url, body, { params: param });
  }

  deleteproposedDiversionIntersection(id: number) {
    let url =
      environment.baseURL + API.DELETE_PROPOSED_DIVERSION_DETAILS_INTERSECTION;
    let param = new HttpParams().append('fcProposedDiversionsDetails_id', id);
    return this.http.post<GenericResponse>(url, null, { params: param });
  }

  deleteLegalStatus(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.FC_A_DELETE_LEGAL_STATUS_DETAILS;
    let param = new HttpParams().append('fc_legal_status_id', id);
    return this.http.post<any>(url, null, { params: param });
  }

  // deleteFcPartbComponent(cd_id: any): Observable<GenericResponse> {
  //   let url = environment.baseURL + API.DELETE_FC_COMPONENET_DETAILS;
  //   let param = new HttpParams().append('cd_id', cd_id);
  //   return this.http.post<GenericResponse>(url, null, { params: param });
  // }

  deleteRelationalData(endPoint: string, cd_id: number): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.http
        .post<GenericResponse>(endPoint, {}, { params: { cd_id: cd_id } })
        .subscribe({
          next: (response) => {
            if (response.status === 200) this.showMessage(true);
            else this.showMessage(false);
            resolve(response.status === 200);
          },
          error: () => {
            this.showMessage(false);
            resolve(false);
          },
        });
    });
  }
  showMessage(isSuccess: boolean) {
    if (isSuccess)
      this.msgService.showSuccess(
        this.translateService.instant('ec.resource_details_success')
      );
    else
      this.msgService.showError(this.translateService.instant('generic.error'));
  }

  saveComponent(body: any, fc_id: number): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_COMPONENT;
    let param = new HttpParams().append('fc_id', fc_id);
    return this.http.post<GenericResponse>(url, body, { params: param });
  }

  deleteComponent(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.DELETE_COMPONENT;
    let param = new HttpParams().append('id', id);
    return this.http.post<GenericResponse>(url, null, { params: param });
  }

  deleteProposedDiversionLand(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.DELETE_PROPOSED_DIVERSION_DETAILS;
    let param = new HttpParams().append('id', id);
    return this.http.post<GenericResponse>(url, null, { params: param });
  }

  deleteFcBProposedDiversionLand(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.DELETE_FC_B_PROPOSED_DIVERSION_DETAILS;
    let param = new HttpParams().append('fc_form_b_proposed_diversion_id', id);
    return this.http.post<GenericResponse>(url, null, { params: param });
  }

  getComponent(fc_id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_FC_COMPONENT;
    let param = new HttpParams().append('fc_id', fc_id);
    return this.http.post<GenericResponse>(url, null, { params: param });
  }

  getProposedDiversionDetails(fc_id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_PROPOSED_DIVERSION_KML_DATA;
    let param = new HttpParams().append('fc_id', fc_id);
    return this.http.get<GenericResponse>(url, { params: param });
  }

  deletePatchKmlData(id: any[]): Observable<GenericResponse> {
    let url = environment.baseURL + API.DELETE_PATCH_KML;
    return this.http.post<GenericResponse>(url, id);
  }

  deletePatchKmlDataAfforestation(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.DELETE_PATCH_KML_AFFORESTATION;
    return this.http.post<GenericResponse>(
      url,
      {},
      {
        params: { id: id },
      }
    );
  }

  saveAdditionalInformation(
    body: any,
    fc_id: number
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_ADDITIONAL_INFORMATION;
    let param = new HttpParams().append('fcId', fc_id);
    return this.http.post<GenericResponse>(url, body, { params: param });
  }

  deleteAdditionalInformation(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.DElETE_ADDITIONAL_INFORMATION;
    let param = new HttpParams().append('id', id);
    return this.http.post<GenericResponse>(url, null, { params: param });
  }

  getApplicationName(clearence: string): Observable<GenericResponse> {
    let url = environment.baseURL + API.APPLICATION_NAME;
    let param = new HttpParams().append('Id', clearence);
    return this.http.post<any>(url, {}, { params: param });
  }

  getAdditionalInformation(fc_id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_ADDITIONAL_INFORMATION;
    let param = new HttpParams().append('fc_id', fc_id);
    return this.http.post<GenericResponse>(url, null, { params: param });
  }

  getScenario(body: any) {
    body.variables.pptype.value = body.variables.pptype.value.startsWith(
      'Trust/'
    )
      ? 'Trust/Association/Society'
      : body.variables.pptype.value;
    return this.http.post<GenericResponse>(
      environment.baseURLEngineRest + API.GET_SCENARIO,
      body
    );
  }

  saveMiningMineralOilProposal(
    body: any,
    id: any
  ): Observable<GenericResponse> {
    return this.http.post<GenericResponse>(
      environment.baseURL + API.SAVE_MINING_MINERAL_OIL,
      body,
      {
        params: { fcId: id },
      }
    );
  }

  deleteeMiningMineralOilArrayData(
    id: string,
    URL: string
  ): Observable<GenericResponse> {
    return this.http.post<GenericResponse>(
      environment.baseURL + URL,
      {},
      {
        params: { id: id },
      }
    );
  }
}
