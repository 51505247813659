import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { MiningProposal } from 'src/app/models/environment-clearance-part-B/mining-proposal.model';
import { FcAforestationDetail } from 'src/app/models/forest-clearance/fc-aforestation-details.model';
import { FcOtherDetails } from 'src/app/models/forest-clearance/fc-other.model';
import { FcProposedLand } from 'src/app/models/forest-clearance/fc-proposed-land.model';
// import { AfforestationDetails } from 'src/app/models/fc-a-partb/afforestation-details';
// import { FCPArtbBasicDetails } from 'src/app/models/fc-a-partb/basic-details.model';
import { GenericResponse } from 'src/app/models/shared/generic-response.model';
import { EcOtherDetails } from 'src/app/models/environment-clearence/ec-other-details.model';
import { API } from 'src/app/shared/app-constant';
import { environment } from 'src/environments/environment';
import { MessageService } from '../message.service';

@Injectable({
  providedIn: 'root',
})
export class FcBService {
  constructor(
    private http: HttpClient,
    private msgService: MessageService,
    private translateService: TranslateService
  ) {}
  showMessage(isSuccess: boolean) {
    if (isSuccess)
      this.msgService.showSuccess(
        this.translateService.instant('ec.resource_details_success')
      );
    else
      this.msgService.showError(this.translateService.instant('generic.error'));
  }

  getProjectCategory(): Observable<any> {
    let url = environment.baseURLMis + API.GET_FC_PROJECT_CATEGORY;
    let param = new HttpParams().append('isactive', true);
    return this.http.get<any>(url, { params: param });
  }

  // getFcFormbProjectDetails(fc_form_b_id: number, step:number): Observable<any> {
  //   let url = environment.baseURL + API.GET_FC_FORM_B_PROJECT_DETAILS;
  //   let param = new HttpParams().append('fc_form_b_id': fc_form_b_id, step: step );
  //   return this.http.post<GenericResponse>(url, {}, { params: param });
  // }

  getFcFormbProjectDetails(
    fc_form_b_id: any,
    step: number
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_FC_FORM_B_PROJECT_DETAILS;
    let param = { fc_form_b_id: fc_form_b_id, step: step };
    return this.http.post<GenericResponse>(url, {}, { params: param });
  }

  getProposedDiversionDetails(fc_form_b_id: any): Observable<GenericResponse> {
    let url =
      environment.baseURL + API.GET_FC_FORM_B_PROPOSED_DIVERSION_KML_DATA;
    let param = new HttpParams().append('fc_form_b_id', fc_form_b_id);
    return this.http.post<GenericResponse>(url, '', { params: param });
  }

  saveFCFormBProjectDetails(
    body: any,
    fcId: number,
    cafId: string,
    clearenceId: any
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_FC_FORM_B_PROJECT_DETAILS;
    let param = new HttpParams()
      .append('fc_id', fcId)
      .append('caf_id', cafId)
      .append('clearance_id', clearenceId);
    return this.http.post<GenericResponse>(url, body, { params: param });
  }
  // saveFCFormBProjectDetails(
  //   body: any,
  //   cafId: string,
  //   clearenceId:number
  // ): Observable<GenericResponse> {
  //   let url = environment.baseURL + API.SAVE_FC_FORM_B_PROJECT_DETAILS;
  //   let param = { caf_Id: cafId,clearance_id: clearenceId};
  //   return this.http.post<GenericResponse>(url, body, { params: param });
  // }

  saveFcFormbComplianceDetails(
    body: any,
    fc_form_b_id: number
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_FC_FORM_B_COMPLIANCE_DETAILS;
    let param = new HttpParams().append('fc_form_b_id', fc_form_b_id);
    return this.http.post<GenericResponse>(url, body, { params: param });
  }

  deleteLegalStatus(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.FC_B_DELETE_LEGAL_STATUS_DETAILS;
    let param = new HttpParams().append('id', id);
    return this.http.post<any>(url, null, { params: param });
  }

  saveFcFormbPaymentDetails(
    body: any,
    fc_form_b_id: number
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_FC_FORM_B_PAYMENT_DETAILS;
    let param = new HttpParams().append('fc_form_b_id', fc_form_b_id);
    return this.http.post<GenericResponse>(url, body, { params: param });
  }

  saveFcFormbPatchAreaDetails(
    body: any,
    fc_form_b_id: number
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_FC_FORM_B_PATCH_AREA_DETAILS;
    let param = new HttpParams().append('fc_form_b_id', fc_form_b_id);
    return this.http.post<GenericResponse>(url, body, { params: param });
  }

  saveFcFormbLeaseDetails(
    body: any,
    fc_form_b_id: number
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_FC_FORM_B_LEASE_DETAILS;
    let param = new HttpParams().append('fc_form_b_id', fc_form_b_id);
    return this.http.post<GenericResponse>(url, body, { params: param });
  }

  saveFcFormbApprovalDetails(
    body: any,
    fc_form_b_id: number
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_FC_FORM_B_APPROVAL_DETAILS;
    let param = new HttpParams().append('fc_form_b_id', fc_form_b_id);
    return this.http.post<GenericResponse>(url, body, { params: param });
  }

  saveFcFormbPriorProposalsDetails(
    body: any,
    fc_form_b_id: number
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_FC_FORM_B_PRIOR_PROPOSALS_DETAILS;
    let param = new HttpParams().append('fc_form_b_id', fc_form_b_id);
    return this.http.post<GenericResponse>(url, body, { params: param });
  }

  saveFcFormBProposedLandDetails(
    body: FcProposedLand,
    fc_form_b_id: string
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_FC_FORM_B_PROPOSED_LAND;
    let param = new HttpParams().append('fc_form_b_id', fc_form_b_id);
    return this.http.post<GenericResponse>(url, body, { params: param });
  }

  saveProposedDiversion(
    body: any[],
    fc_form_b_id: number
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_FC_FORM_B_PROPOSED_DIVERSION;
    let param = new HttpParams().append('fc_form_b_id', fc_form_b_id);
    return this.http.post<GenericResponse>(url, body, { params: param });
  }

  saveAforestationDetails(
    body: FcAforestationDetail,
    fc_form_b_id: string
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_FC_FORM_B_AFORESTATION_DETAILS;
    let param = new HttpParams().append('fc_form_b_id', fc_form_b_id);
    return this.http.post<GenericResponse>(url, body, { params: param });
  }

  saveFcPatchKmlDetails(
    body: any,
    fc_form_b_id: number
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_FC_FORM_B_PATCHWISE_DETAILS;
    let param = new HttpParams().append('fc_form_b_id', fc_form_b_id);
    return this.http.post<GenericResponse>(url, body, { params: param });
  }

  getFormBPatchDetails(fc_form_b_id: number): Observable<GenericResponse> {
    let url = environment.baseURL + API.GET_FC_FORM_B_PATCH_DETAILS;
    let param = { fc_form_b_id: fc_form_b_id };
    return this.http.post<GenericResponse>(url, {}, { params: param });
  }

  saveMiningProposal(body: MiningProposal, id: number) {
    return this.http.post<GenericResponse>(
      environment.baseURL + API.SAVE_FC_FORM_B_MINING_PROPOSAL,
      body,
      {
        params: { fc_form_b_id: id },
      }
    );
  }

  saveComponent(body: any, fc_form_b_id: number): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_COMPONENT_FC_FORM_B;
    let param = new HttpParams().append('fc_form_b_id', fc_form_b_id);
    return this.http.post<GenericResponse>(url, body, { params: param });
  }
  saveUndertaking(body: EcOtherDetails, fc_form_b_id: any, is_submit: boolean): Observable<any> {
    return this.http.post<any>(environment.baseURL + API.SAVE_FC_FORM_B_UNDERTAKING, body, { params: {fc_form_b_id, is_submit} });
  }

  saveOtherDetails(
    body: FcOtherDetails,
    fc_form_b_id: number
  ): Observable<GenericResponse> {
    let url = environment.baseURL + API.SAVE_FC_FORM_B_OTHERS_DETAIL;
    let param = new HttpParams().append('fc_form_b_id', fc_form_b_id);
    return this.http.post<GenericResponse>(url, body, { params: param });
  }

  deleteProposedDiversionLand(id: any): Observable<GenericResponse> {
    let url =
      environment.baseURL + API.DELETE_FC_FORM_B_PROPOSED_DIVERSION_KML_DATA;
    let param = new HttpParams().append('fc_form_b_proposed_diversion_id', id);
    return this.http.post<GenericResponse>(url, null, { params: param });
  }
  deleteComponent(id: any): Observable<GenericResponse> {
    let url = environment.baseURL + API.DELETE_FC_FORM_B_COMPONENT;
    let param = new HttpParams().append('fc_form_b_component_details_id', id);
    return this.http.post<GenericResponse>(url, null, { params: param });
  }
  //DELETE_FC_FORM_B_COMPLIANCE

  // deleteCompliance(id: any): Observable<GenericResponse> {
  //   let url = environment.baseURL + API.DELETE_FC_FORM_B_COMPLIANCE;
  //   let param = new HttpParams().append('fc_form_b_compliance_details_id', id);
  //   return this.http.post<GenericResponse>(url, null, { params: param });
  // }

  // deletePriorProposals(approvalId:number):Observable<GenericResponse>{
  //   let url = environment.baseURL + API.DELETE_FC_FORM_B_PRIOR_PROPOSALS;
  //   let param = {fc_form_b_prior_proposals_id:approvalId};
  //   return this.http.post<GenericResponse>(url,{},{params:param});
  // }

  deleteRelationalData(endPoint: string, cd_id: number): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.http
        .post<GenericResponse>(
          endPoint,
          {},
          { params: { fc_form_b_prior_proposals_id: cd_id } }
        )
        .subscribe({
          next: (response) => {
            if (response.status === 200) this.showMessage(true);
            else this.showMessage(false);
            resolve(response.status === 200);
          },
          error: () => {
            this.showMessage(false);
            resolve(false);
          },
        });
    });
  }

  deleteRelationalDataCompliance(
    endPoint: string,
    cd_id: number
  ): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.http
        .post<GenericResponse>(
          endPoint,
          {},
          { params: { fc_form_b_compliance_details_id: cd_id } }
        )
        .subscribe({
          next: (response) => {
            if (response.status === 200) this.showMessage(true);
            else this.showMessage(false);
            resolve(response.status === 200);
          },
          error: () => {
            this.showMessage(false);
            resolve(false);
          },
        });
    });
  }

  deleteRelationalDataPayment(
    endPoint: string,
    cd_id: number
  ): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.http
        .post<GenericResponse>(
          endPoint,
          {},
          { params: { fc_form_b_payment_details_id: cd_id } }
        )
        .subscribe({
          next: (response) => {
            if (response.status === 200) this.showMessage(true);
            else this.showMessage(false);
            resolve(response.status === 200);
          },
          error: () => {
            this.showMessage(false);
            resolve(false);
          },
        });
    });
  }

  deleteRelationalDataPatch(endPoint: string, cd_id: number): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.http
        .post<GenericResponse>(
          endPoint,
          {},
          { params: { fc_form_b_patch_area_details_id: cd_id } }
        )
        .subscribe({
          next: (response) => {
            if (response.status === 200) this.showMessage(true);
            else this.showMessage(false);
            resolve(response.status === 200);
          },
          error: () => {
            this.showMessage(false);
            resolve(false);
          },
        });
    });
  }

  deleteRelationalDataApproval(
    endPoint: string,
    cd_id: number
  ): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.http
        .post<GenericResponse>(
          endPoint,
          {},
          { params: { fc_form_b_approval_details_id: cd_id } }
        )
        .subscribe({
          next: (response) => {
            if (response.status === 200) this.showMessage(true);
            else this.showMessage(false);
            resolve(response.status === 200);
          },
          error: () => {
            this.showMessage(false);
            resolve(false);
          },
        });
    });
  }
}
