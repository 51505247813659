import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class HttpInterceptorClass implements HttpInterceptor {
  constructor(private route: Router, private authService: AuthService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.authService.isAuthenticated()) {
      req = req.clone({
        setHeaders: { Authorization: this.authService.AUTH_TOKEN },
      });
    }
    if (req.body instanceof FormData) {
      // If it's FormData, extract the `transaction_response`
      const transactionResponse = req.body.get('transaction_response');
      console.log('Transaction Response:', transactionResponse); // Log it to inspect
    } else {
      console.log('Request is not FormData');
    }

   
    return next.handle(req).pipe(
      tap(
        (response) => {
          if (response instanceof HttpResponse) {
          }
        },
        (error) => {
          const status = error?.status || '';
          switch (status) {
            case 404:
              break;
            case 403:
              break;
            case 401:
              {
                if (
                  ['/employee/me', '/campaPayment/getLatestDemand', '/ask'].some(
                    (item) => error?.url?.includes(item)
                  )
                )
                  return;
                this.route.navigate(['/home']);
              }
              break;
          }

          // Check token expiry
          if (
            this.authService.isAuthenticated() &&
            this.authService.isTokenExpired()
          ) {
            // this.broadCastService.broadcast(AUTH_TOKEN_EXPIRED);
          }

          return throwError(error);
        }
      )
    );
  }
}

// this.authService.AUTH_TOKEN
