<div *ngIf="isError" class="text-danger container d-none">
  <h1>Oops! Please verify the link Something Went Wrong</h1>
</div>
<div *ngIf="status == 2" class="container">
  <h1 class="text-danger">Aadhar verification failed.</h1>
  <button class="btn btn-primary" (click)="getAadharSignature()">Try Again</button>
</div>
<form action="https://nic-esigngateway.nic.in/eSign21/acceptClient" id="eSignKycForm" name="search-theme-form"
  method="POST" enctype="multipart/form-data">

  <input type="hidden" name="username" id="username" value="{{ data?.user_name }}" />

  <input type="hidden" name="clientrequestURL" id="clientrequestURL" value="{{ data?.clientrequestURL }}" />

  <input type="text" name="document" style="display: none;">

  <textarea rows="60" cols="50" id="xmlVar" name="xml" style="display: none">{{ data?.xml }}</textarea>

  <input type="hidden" name="clientId" value="{{ data?.clientId }}" />

  <input type="hidden" name="userId" value="{{ data?.userId }}" />

  <input type="hidden" id="Content-Type" name="Content-Type" value="application/xml" />

</form>